























import { AccountSignerSelectorTs } from './AccountSignerSelectorTs';
export default class AccountSignerSelector extends AccountSignerSelectorTs {}
